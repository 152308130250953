import React, {lazy, Suspense} from "react";
import Idle from 'react-idle'
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import BrowserRouter from "./BrowserRouter";
import uuid from "./utils/uuid";
import UserSessionService from "./services/UserSessionService";
import {permissions} from "./utils/permissions";
import AuthenticationService from "./services/AuthenticationService";
import {config} from "./config";
import GenericUtils from "./utils/GenericUtils";
import MainLoading from "./components/loading/MainLoading";
import RuleDetailsFormContainer from "./views/rules/rules/generic/components/RuleDetailsFormContainer";

const MainLayout = lazy(() => import("./layouts/MainLayout"));
const BlankLayout = lazy(() => import("./layouts/BlankLayout"));
const NotFoundView = lazy(() => import("./views/404/NotFound"));
const LoginView = lazy(() => import("./views/auth/LoginView"));
const TerminalManagementView = lazy(() => import("./views/terminals/TerminalListView"));
const UserManagementView = lazy(() => import("./views/users/UserListView"));
const RoleManagementView = lazy(() => import("./views/roles/RoleManagementView"));
const AuditListView = lazy(() => import("./views/audit/AuditListView"));
const TariffManagementView = lazy(() => import("./views/tariffs/TariffListView"));
const EntityManagementView = lazy(() => import("./views/entities/EntityListView"));
const EventListView = lazy(() => import("./views/events/EventListView"));
const InterfaceManagementView = lazy(() => import("./views/interfaces/InterfaceListView"));
const RuleListView = lazy(() => import("./views/rules/rules/generic/RuleListView"));
const BatchManagementView = lazy(() => import("./views/batches/BatchListView"));
const GeneticsListView = lazy(() => import( './views/batches/genetics/GeneticsListView'));
const FeedTypesListView = lazy(() => import( './views/batches/feedTypes/FeedTypesListView'));
const BaseBatchDetails = lazy(() => import("./views/batches/managementView/BaseBatchDetails"));
const SetPasswordView = lazy(() => import("./views/auth/ResetPasswordView"));
const ForgotPasswordView = lazy(() => import("./views/auth/ForgotPasswordView"));
const HomepageView = lazy(() => import("./views/home/HomepageView"));
const ApiListView = lazy(() => import("./views/api/ApiListView"));
const AuthenticatedRoute = lazy(() => import("./views/auth/AuthenticatedRoute"));
const FeedFactoryListView = lazy(() => import( './views/batches/feedFactories/FeedFactoryListView'));
const CurveListView = lazy(() => import( './views/batches/curves/CurveListView'));
const DeathReasonsListView = lazy(() => import( './views/batches/deathReasons/DeathReasonsListView'));
const CurveDetailsListView = lazy(() => import( './views/batches/curves/form/CurveDetailsListView'));
const FeedMovementsListView = lazy(() => import("./views/batches/feedMovements/FeedMovementsListView"));
const AnimalMovementsListView = lazy(() => import("./views/batches/animalMovements/AnimalMovementsListView"));
const AutomaticFeedMovementsListView = lazy(() => import("./views/batches/automaticFeedMovements/AutomaticFeedMovementsListView"));
const TaskTemplateListView = lazy(() => import("./views/tasks/templates/TaskTemplateListView"));
const TaskListView = lazy(() => import( './views/tasks/tasks/TaskListView'));
const TaskBoardView = lazy(() => import( './views/tasks/board/TaskBoardView'));
const SubscriptionListView = lazy(() => import("./views/reports/SubscriptionListView"));
const ManualWeighingFormContainer = lazy(() => import("./views/batches/manualWeighing/form/ManualWeighingFormContainer"));
const ManualWeighingListView = lazy(() => import("./views/batches/manualWeighing/ManualWeighingListView"));
const BatchDetailsFormContainer = lazy(() => import("./views/batches/components/BatchDetailsFormContainer"));
const GeneticsDetailsForm = lazy(() => import("./views/batches/genetics/form/GeneticsDetailsFormContainer"));
const CurveDetailsForm = lazy(() => import("./views/batches/curves/form/CurveDetailsForm"));
const DeathReasonsDetailsForm = lazy(() => import("./views/batches/deathReasons/form/DeathReasonsDetailsForm"));
const FeedTypesDetailsForm = lazy(() => import("./views/batches/feedTypes/form/FeedTypesDetailsForm"));
const FeedFactoryDetailsForm = lazy(() => import("./views/batches/feedFactories/form/FeedFactoryDetailsForm"));
const AnimalMovementsDetailsFormContainer = lazy(() => import("./views/batches/animalMovements/detailsForm/AnimalMovementsDetailsFormContainer"));
const FeedTransactionDetailsFormContainer = lazy(() => import("./views/batches/feedMovements/detailsForm/FeedTransactionDetailsFormContainer"));
const MultiFeedTransactionDetailsForm = lazy(() => import("./views/batches/feedMovements/detailsForm/multiForm/MultiFeedTransactionDetailsForm"));
const EventDetailsFormContainer = lazy(() => import("./views/events/components/EventDetailsFormContainer"));
const TaskTemplateFormContainer = lazy(() => import("./views/tasks/templates/form/TaskTemplateFormContainer"));
const TaskFormContainer = lazy(() => import("./views/tasks/tasks/form/TaskFormContainer"));
const AuditDetailsFormContainer = lazy(() => import("./views/audit/AuditDetailsFormContainer"));
const EntityDetailsForm = lazy(() => import("./views/entities/components/EntityDetailsForm"));
const ReportFormContainer = lazy(() => import("./views/reports/components/ReportFormContainer"));
const TerminalFormContainer = lazy(() => import("./views/terminals/components/form/TerminalFormContainer"));
const TerminalDetailsContainer = lazy(() => import("./views/terminals/components/details/TerminalDetailsContainer"));
const InterfaceDetailsFormContainer = lazy(() => import("./views/interfaces/components/InterfaceDetailsFormContainer"));
const RuleTemplateListView = lazy(() => import("./views/rules/templates/RuleTemplateListView"));
const RuleTemplateFormContainer = lazy(() => import("./views/rules/templates/form/RuleTemplateFormContainer"));
const AlarmEntityViewContainer = lazy(() => import("./views/rules/rules/alarms/AlarmEntityViewContainer"));
const RuleAlarmDetailsFormContainer = lazy(() => import("./views/rules/rules/alarms/components/details/RuleAlarmDetailsFormContainer"));
const SchedulerEntityViewContainer = lazy(() => import("./views/rules/rules/schedulers/SchedulerEntityViewContainer"));
const RuleSchedulerDetailsFormContainer = lazy(() => import("./views/rules/rules/schedulers/components/scheduler/RuleSchedulerDetailsFormContainer"));
const TariffDetailsFormContainer = lazy(() => import("./views/tariffs/form/TariffDetailsFormContainer"));
const UserDetailsFormContainer = lazy(() => import("./views/users/form/UserDetailsFormContainer"));
const ApiDetailsFormContainer = lazy(() => import("./views/api/components/form/ApiDetailsFormContainer"));
const ApiTerminalsViewContainer = lazy(() => import("./views/api/components/terminalSubscription/ApiTerminalsViewContainer"));
const UserProfileContainer = lazy(() => import("./views/users/profile/UserProfileContainer"));
const TariffPeriodContainer = lazy(() => import("./views/tariffs/periods/TariffPeriodContainer"));
const TermsOfUse = lazy(() => import("./legal/termsOfUse/TermsOfUse"));
const DataProtectionNotice = lazy(() => import("./legal/dataprotection/DataProtectionNotice"));
const RulesGroupsViewContainer = lazy(() => import("./views/rules/rules/groups/RuleGroupsViewContainer"));
const PublicDashboardView = lazy(() => import("./views/home/PublicDashboardView"));
const AnalyticsOverviewContainer = lazy(() => import("./views/analytics/overview/AnalyticsOverviewContainer"));
const AnalyticsBatchOverviewContainer = lazy(() => import("./views/analytics/batch/AnalyticsBatchContainer"));

const Routes = () => (
    <Router>
        <Idle
            timeout={config.generic.sessionTimeout.idleTimeout}
            render={ ({idle}) => {
                if(idle && GenericUtils.pathHasAuthenticationAndIsNotHomePage(window.location.pathname)){
                    AuthenticationService.logout(true, () => {}, () => {
                        AuthenticationService.logoutWithoutRequest();
                    });
                    return null;
                } else {
                    return (
                        <BrowserRouter>
                            <Switch>
                                <Route exact path="/" render={appProps => renderHomeRoute(appProps)}/>

                                <Route path="/farmview/public-dashboard" render={appProps => renderPublicDashboardRoute(appProps)}/>
                                <Route path="/farmview" render={appProps => renderHomeRoute(appProps)}/>

                                <Route path="/terms-of-use" render={appProps => renderTermsOfUse(appProps)}/>
                                <Route path="/data-protection-notice" render={appProps => renderDataProtectionNotice(appProps)}/>

                                <Route path="/login" render={appProps => renderLoginRoute(appProps)}/>

                                <Route path="/account/:userid/activation/:activationcode" render={appProps => renderResetPasswordRoute(appProps, true)}/>
                                <Route path="/account/:userid/reset/:resetcode" render={appProps => renderResetPasswordRoute(appProps, false)}/>
                                <Route path="/forgot_password" render={appProps => renderForgotPasswordRoute(appProps)}/>

                                <Route path="/system/users/details/:userid" render={appProps => renderUsersFormRoute(appProps)}/>
                                <Route path="/system/users/create" render={appProps => renderUsersFormRoute(appProps)}/>
                                <Route path="/system/users" render={appProps => renderUsersRoute(appProps)}/>

                                <Route path="/system/terminals/create" render={appProps => renderTerminalFormRoute(appProps)}/>
                                <Route path="/system/terminals/details/:terminalId" render={appProps => renderTerminalDetailsRoute(appProps)}/>
                                <Route path="/system/terminals/:terminalId" render={appProps => renderTerminalFormRoute(appProps)}/>
                                <Route path="/system/terminals" render={appProps => renderTerminalsRoute(appProps)}/>

                                <Route path="/system/roles" render={appProps => renderRolesRoute(appProps)}/>

                                <Route path="/system/tariffs/:tariffid/periods" render={appProps => renderTariffPeriodsRoute(appProps)}/>
                                <Route path="/system/tariffs/:tariffid/details" render={appProps => renderTariffFormRoute(appProps)}/>
                                <Route path="/system/tariffs/create" render={appProps => renderTariffFormRoute(appProps)}/>
                                <Route path="/system/tariffs" render={appProps => renderTariffRoute(appProps)}/>

                                <Route path="/system/audit/:auditid" render={appProps => renderAuditForm(appProps)}/>
                                <Route path="/system/audit" render={appProps => renderAuditRoute(appProps)}/>

                                <Route path="/system/entities/:entityid/details" render={appProps => renderEntitiesFormRoute(appProps)}/>
                                <Route path="/system/entities/create" render={appProps => renderEntitiesFormRoute(appProps)}/>
                                <Route path="/system/entities" render={appProps => renderEntitiesRoute(appProps)}/>

                                <Route path="/system/interfaces/create" render={appProps => renderInterfacesFormRoute(appProps)}/>
                                <Route path="/system/interfaces/details/:interfaceId" render={appProps => renderInterfacesFormRoute(appProps)}/>
                                <Route path="/system/interfaces" render={appProps => renderInterfacesRoute(appProps)}/>

                                <Route path="/system/api-access/:apiid/subscriptions" render={appProps => renderApiTerminalsRoute(appProps)}/>
                                <Route path="/system/api-access/:apiid/details" render={appProps => renderApiTerminalFormRoute(appProps)}/>
                                <Route path="/system/api-access/create" render={appProps => renderApiTerminalFormRoute(appProps)}/>
                                <Route path="/system/api-access" render={appProps => renderApiRoute(appProps)}/>

                                <Route path="/production/batches/create" render={appProps => renderBatchFormRoute(appProps)}/>
                                <Route path="/production/batches/:batchId/details" render={appProps => renderBatchDetailsRoute(appProps)}/>
                                <Route path="/production/batches/:batchId" render={appProps => renderBatchFormRoute(appProps)}/>
                                <Route path="/production/batches" render={appProps => renderBatchesRoute(appProps)}/>

                                <Route path="/production/manualWeighing/create" render={appProps => renderManualWeighingFormRoute(appProps)}/>
                                <Route path="/production/:batchId/manualWeighing/create" render={appProps => renderManualWeighingFormRoute(appProps)}/>
                                <Route path="/production/:batchId/manualWeighing/:manualWeighingId" render={appProps => renderManualWeighingFormRoute(appProps)}/>
                                <Route path="/production/manualWeighing/:manualWeighingId" render={appProps => renderManualWeighingFormRoute(appProps)}/>
                                <Route path="/production/manualWeighing" render={appProps => renderManualWeighingPageRoute(appProps)}/>

                                <Route path="/production/genetics/details/:geneticId" render={appProps => renderGeneticsFormRoute(appProps)}/>
                                <Route path="/production/genetics/create" render={appProps => renderGeneticsFormRoute(appProps)}/>
                                <Route path="/production/genetics" render={appProps => renderGeneticsRoute(appProps)}/>

                                <Route path="/production/curves/details/:curveId/curve" render={appProps => renderProductionCurveDetailsRoute(appProps)}/>
                                <Route path="/production/curves/details/:curveId" render={appProps => renderProductionCurvesFormRoute(appProps)}/>
                                <Route path="/production/curves/create" render={appProps => renderProductionCurvesFormRoute(appProps)}/>
                                <Route path="/production/curves" render={appProps => renderProductionCurvesRoute(appProps)}/>

                                <Route path="/production/deathReasons/details/:deathReasonsId" render={appProps => renderDeathReasonsFormRoute(appProps)}/>
                                <Route path="/production/deathReasons/create" render={appProps => renderDeathReasonsFormRoute(appProps)}/>
                                <Route path="/production/deathReasons" render={appProps => renderDeathReasonsRoute(appProps)}/>

                                <Route path="/production/feedTypes/details/:feedTypeId" render={appProps => renderFeedTypesFormRoute(appProps)}/>
                                <Route path="/production/feedTypes/create" render={appProps => renderFeedTypesFormRoute(appProps)}/>
                                <Route path="/production/feedTypes" render={appProps => renderFeedTypesRoute(appProps)}/>

                                <Route path="/production/feedFactories/details/:feedFactoryId" render={appProps => renderFeedFactoryFormRoute(appProps)}/>
                                <Route path="/production/feedFactories/create" render={appProps => renderFeedFactoryFormRoute(appProps)}/>
                                <Route path="/production/feedFactories" render={appProps => renderFeedFactoryRoute(appProps)}/>

                                <Route path="/production/:batchId/feedMovements/details/:feedMovementId" render={appProps => renderFeedMovementsFormRoute(appProps)}/>
                                <Route path="/production/:batchId/feedMovements/create" render={appProps => renderFeedMovementsFormRoute(appProps)}/>
                                <Route path="/production/feedMovements/details/:feedMovementId" render={appProps => renderFeedMovementsFormRoute(appProps)}/>
                                <Route path="/production/feedMovements/multi-create" render={appProps => renderFeedMovementsMultiFormRoute(appProps)}/>
                                <Route path="/production/feedMovements/create" render={appProps => renderFeedMovementsFormRoute(appProps)}/>
                                <Route path="/production/feedMovements" render={appProps => renderFeedMovementsRoute(appProps)}/>


                                <Route path="/production/:batchId/animalMovements/details/:animalMovementId" render={appProps => renderAnimalsMovementsFormRoute(appProps)}/>
                                <Route path="/production/:batchId/animalMovements/create" render={appProps => renderAnimalsMovementsFormRoute(appProps)}/>
                                <Route path="/production/animalMovements/details/:animalMovementId" render={appProps => renderAnimalsMovementsFormRoute(appProps)}/>
                                <Route path="/production/animalMovements/create" render={appProps => renderAnimalsMovementsFormRoute(appProps)}/>
                                <Route path="/production/animalMovements" render={appProps => renderAnimalsMovementsRoute(appProps)}/>

                                <Route path="/production/automaticFeedMovements" render={appProps => renderAutomaticFeedMovementsRoute(appProps)}/>

                                <Route path="/production/batch-analytics" render={appProps => renderAnalyticsBatchRoute(appProps)}/>

                                <Route path="/rules/template/:ruleTemplateId/details" render={appProps => renderRuleTemplateFormRoute(appProps)}/>
                                <Route path="/rules/template/create" render={appProps => renderRuleTemplateFormRoute(appProps)}/>
                                <Route path="/rules/template/" render={appProps => renderRuleTemplateListRoute(appProps)}/>


                                <Route path="/rules/:ruleId/details" render={appProps => renderRuleDetailsRoute(appProps)}/>
                                <Route path="/rules/create" render={appProps => renderRuleDetailsRoute(appProps)}/>
                                <Route path="/rules/alarms/:ruleId/details" render={appProps => renderAlarmRuleDetailsRoute(appProps)}/>
                                <Route path="/rules/alarms/create" render={appProps => renderAlarmRuleDetailsRoute(appProps)}/>
                                <Route path="/rules/alarms" render={appProps => renderAlarmRulesRoute(appProps)}/>
                                <Route path="/rules/scheduler/:ruleId/details" render={appProps => renderSchedulerRuleDetailsRoute(appProps)}/>
                                <Route path="/rules/scheduler/create" render={appProps => renderSchedulerRuleDetailsRoute(appProps)}/>
                                <Route path="/rules/scheduler" render={appProps => renderSchedulerRulesRoute(appProps)}/>
                                <Route path="/rules/groups" render={appProps => renderRulesGroupsRoute(appProps)}/>
                                <Route path="/rules" render={appProps => renderRulesRoute(appProps)}/>

                                <Route path="/production/:batchId/events/details/:eventId" render={appProps => renderEventsFormRoute(appProps)}/>
                                <Route path="/production/:batchId/events/create" render={appProps => renderEventsFormRoute({...appProps, isBatch:true})}/>
                                <Route path="/events/:eventId/details" render={appProps => renderEventsFormRoute(appProps)}/>
                                <Route path="/events/create" render={appProps => renderEventsFormRoute(appProps)}/>
                                <Route path="/events" render={appProps => renderEventsRoute(appProps)}/>

                                <Route path="/system/helpdesk/:eventId/details" render={appProps => renderEventsFormRoute({...appProps, helpdesk:true})}/>
                                <Route path="/system/helpdesk/create" render={appProps => renderEventsFormRoute({...appProps, helpdesk:true})}/>
                                <Route path="/system/helpdesk" render={appProps => renderEventsRoute({...appProps, helpdesk:true})}/>
                                <Route path="/helpdesk/:eventId/details" render={appProps => renderEventsFormRoute({...appProps, helpdesk:true})}/>
                                <Route path="/helpdesk/create" render={appProps => renderEventsFormRoute({...appProps, helpdesk:true})}/>
                                <Route path="/helpdesk" render={appProps => renderEventsRoute({...appProps, helpdesk:true})}/>

                                <Route path="/tasks/templates/details/:taskTemplateId" render={appProps => renderTaskTemplateFormRoute(appProps)}/>
                                <Route path="/tasks/templates/create" render={appProps => renderTaskTemplateFormRoute(appProps)}/>
                                <Route path="/tasks/templates" render={appProps => renderTaskTemplateRoute(appProps)}/>

                                <Route path="/tasks/taskboard" render={appProps => renderTaskBoardRoute(appProps)}/>

                                <Route path="/production/:batchId/tasks/details/:taskId" render={appProps => renderTaskFormRoute(appProps)}/>
                                <Route path="/production/:batchId/tasks/create" render={appProps => renderTaskFormRoute(appProps)}/>
                                <Route path="/tasks/details/:taskId" render={appProps => renderTaskFormRoute(appProps)}/>
                                <Route path="/tasks/create" render={appProps => renderTaskFormRoute(appProps)}/>
                                <Route path="/tasks" render={appProps => renderTaskRoute(appProps)}/>

                                <Route path="/reports/create" render={appProps => renderReportSubscriptionFormRoute(appProps)}/>
                                <Route path="/reports/:reportId/details" render={appProps => renderReportSubscriptionFormRoute(appProps)}/>
                                <Route path="/reports" render={appProps => renderReportSubscriptionRoute(appProps)}/>

                                <Route path="/analytics" render={appProps => renderAnalyticsOverviewRoute(appProps)}/>

                                <Route path="/settings/profile" render={appProps => renderUserProfileRoute(appProps)}/>

                                <Route path="/not_found" render={() => renderDefaultRoute()}/>
                                <Route path="*" render={() => renderDefaultRoute()}/>
                            </Switch>
                        </BrowserRouter>
                    );
                }
            }} />
    </Router>
);

function renderUserProfileRoute(props) {
    return (
        <Suspense fallback={<MainLoading/>}>
            <AuthenticatedRoute {...props}>
                <MainLayout pageName={"profile"} {...props}>
                    <UserProfileContainer {...props}/>
                </MainLayout>
            </AuthenticatedRoute>
        </Suspense>
    );
}

function renderHomeRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute {...props}>
            <MainLayout pageName={"dashboard"} shouldAddFullScreenCallback={true} {...props}>
                <HomepageView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderPublicDashboardRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <PublicDashboardView {...props}/>
    </Suspense>;
}

function renderTermsOfUse(props) {
    return <Suspense fallback={<MainLoading/>}>
        <TermsOfUse {...props}/>
    </Suspense>;
}

function renderDataProtectionNotice(props) {
    return <Suspense fallback={<MainLoading/>}>
        <DataProtectionNotice {...props}/>
    </Suspense>;
}

function renderLoginRoute(props) {
    return UserSessionService.isAuthenticated()? <Redirect to={{pathname: '/farmview'}}/> : (
        <Suspense fallback={<MainLoading/>}>
            <BlankLayout {...props}>
                <LoginView {...props}/>
            </BlankLayout>
        </Suspense>
    )
}

function renderResetPasswordRoute(props, activation) {
    return <Suspense fallback={<MainLoading/>}>
        <BlankLayout {...props}>
            <SetPasswordView activation={activation} {...props}/>
        </BlankLayout>
    </Suspense>;
}

function renderForgotPasswordRoute(props) {
    return UserSessionService.isAuthenticated()? <Redirect to={{pathname: '/farmview'}}/> :  (
        <Suspense fallback={<MainLoading/>}>
            <BlankLayout {...props}>
                <ForgotPasswordView {...props}/>
            </BlankLayout>
        </Suspense>
    );
}

function renderTerminalFormRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.terminals.read} {...props}>
            <MainLayout pageName={"terminals"} prefix={["System"]} {...props}>
                <TerminalFormContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderTerminalDetailsRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.terminals.read} {...props}>
            <MainLayout pageName={"terminals"} prefix={["System"]} {...props}>
                <TerminalDetailsContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}


function renderTerminalsRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.terminals.read} {...props}>
            <MainLayout pageName={"terminals"} prefix={["System"]} {...props}>
                <TerminalManagementView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderUsersRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.users.read} {...props}>
            <MainLayout pageName={"users"} prefix={["System"]} {...props}>
                <UserManagementView />
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderUsersFormRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.users.read} {...props}>
            <MainLayout pageName={"users"} prefix={["System"]} {...props}>
                <UserDetailsFormContainer />
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderRulesRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.rules.read} {...props}>
            <MainLayout pageName={"rules"} {...props}>
                <RuleListView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderRulesGroupsRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.rules.rule_groups} {...props}>
            <MainLayout pageName={"ruleGroups"} {...props}>
                <RulesGroupsViewContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderAlarmRulesRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.rules.alarms} {...props}>
            <MainLayout pageName={"rulesAlarm"} {...props}>
                <AlarmEntityViewContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderAlarmRuleDetailsRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.rules.alarms} {...props}>
            <MainLayout pageName={"rulesAlarm"} {...props}>
                <RuleAlarmDetailsFormContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderSchedulerRulesRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.rules.scheduler} {...props}>
            <MainLayout pageName={"rulesScheduler"} {...props}>
                <SchedulerEntityViewContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderSchedulerRuleDetailsRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.rules.alarms} {...props}>
            <MainLayout pageName={"rulesScheduler"} {...props}>
                <RuleSchedulerDetailsFormContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderRuleDetailsRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.rules.read} {...props}>
            <MainLayout pageName={"rules"} {...props}>
                <RuleDetailsFormContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderRuleTemplateListRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.rulesTemplates.read} {...props}>
            <MainLayout pageName={"rulesTemplates"} {...props}>
                <RuleTemplateListView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderRuleTemplateFormRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.rulesTemplates.read} {...props}>
            <MainLayout pageName={"rulesTemplates"} {...props}>
                <RuleTemplateFormContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderRolesRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.roles.read} {...props}>
            <MainLayout pageName={"roles"} prefix={["System"]} {...props}>
                <RoleManagementView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderTariffRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.tariffs.read} {...props}>
            <MainLayout pageName={"tariffs"} prefix={["System"]} {...props}>
                <TariffManagementView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderTariffFormRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.tariffs.read} {...props}>
            <MainLayout pageName={"tariffs"} prefix={["System"]} {...props}>
                <TariffDetailsFormContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderTariffPeriodsRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.tariffs.read} orPermissionName={permissions.tariffs.update} {...props}>
            <MainLayout pageName={"tariffPeriods"}{...props}>
                <TariffPeriodContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderAuditForm(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.audit.read} {...props}>
            <MainLayout pageName={"audit"} prefix={["System"]} {...props}>
                <AuditDetailsFormContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderAuditRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.audit.read} {...props}>
            <MainLayout pageName={"audit"} prefix={["System"]} {...props}>
                <AuditListView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderEntitiesRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.entities.read} {...props}>
            <MainLayout pageName={"entities"} prefix={["System"]} {...props}>
                <EntityManagementView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderEntitiesFormRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.entities.read} {...props}>
            <MainLayout pageName={"entities"} prefix={["System"]} {...props}>
                <EntityDetailsForm {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderEventsRoute(props) {
    const {helpdesk} = props;
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.events.read} {...props}>
            <MainLayout pageName={helpdesk ? "helpdesk" : "events"} {...props}>
                <EventListView
                    key={uuid()}
                    {...props}
                />
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderEventsFormRoute(props) {
    const {helpdesk} = props;
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.events.read} {...props}>
            <MainLayout pageName={helpdesk ? "helpdesk" : "events"} {...props}>
                <EventDetailsFormContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderApiRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.api.read} {...props}>
            <MainLayout pageName={"apiKeys"} prefix={["System"]} {...props}>
                <ApiListView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderApiTerminalsRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.api.read} orPermissionName={permissions.api.update} {...props}>
            <MainLayout pageName={"apiKeys"}  prefix={["System"]} {...props}>
                <ApiTerminalsViewContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderApiTerminalFormRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.api.read} orPermissionName={permissions.api.update} {...props}>
            <MainLayout pageName={"apiKeys"}  prefix={["System"]} {...props}>
                <ApiDetailsFormContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderInterfacesRoute(props){
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.interfaces.read} {...props}>
            <MainLayout pageName={"interfaces"} prefix={["System"]} {...props}>
                <InterfaceManagementView prefix={["System"]} {...props} />
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderInterfacesFormRoute(props){
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.interfaces.read} {...props}>
            <MainLayout pageName={"interfaces"} prefix={["System"]} {...props}>
                <InterfaceDetailsFormContainer prefix={["System"]} {...props} />
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>;
}

function renderBatchesRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.batches.read} {...props}>
            <MainLayout pageName={"batches"} {...props}>
                <BatchManagementView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderManualWeighingFormRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.batches.read} {...props}>
            <MainLayout pageName={"manualWeighingForm"} {...props}>
                <ManualWeighingFormContainer
                    {...props}
                    batchId={props.match.params.batchId}
                    manualWeighingId={props.match.params.manualWeighingId}
                />
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}


function renderManualWeighingPageRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.batches.read} {...props}>
            <MainLayout pageName={"manualWeighingForm"}{...props}>
                <ManualWeighingListView{...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderGeneticsRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.genetics.read} {...props}>
            <MainLayout pageName={"genetics"} {...props}>
                <GeneticsListView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderGeneticsFormRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.genetics.read} {...props}>
            <MainLayout pageName={"genetics"} {...props}>
                <GeneticsDetailsForm {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderProductionCurvesFormRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.curve.read} {...props}>
            <MainLayout pageName={"productionCurves"} {...props}>
                <CurveDetailsForm {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderProductionCurvesRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.curve.read} {...props}>
            <MainLayout pageName={"productionCurves"} {...props}>
                <CurveListView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderProductionCurveDetailsRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.curve.read} {...props}>
            <MainLayout pageName={"productionCurveDetails"}{...props}>
                <CurveDetailsListView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderDeathReasonsRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.deathReasons.read} {...props}>
            <MainLayout pageName={"deathReasons"} {...props}>
                <DeathReasonsListView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderDeathReasonsFormRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.deathReasons.read} {...props}>
            <MainLayout pageName={"deathReasons"} {...props}>
                <DeathReasonsDetailsForm {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderFeedTypesRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.feedTypes.read} {...props}>
            <MainLayout pageName={"feedTypes"} {...props}>
                <FeedTypesListView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderFeedTypesFormRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.feedTypes.read} {...props}>
            <MainLayout pageName={"feedTypes"} {...props}>
                <FeedTypesDetailsForm {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderFeedFactoryRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.feedFactory.read} {...props}>
            <MainLayout pageName={"feedFactories"} {...props}>
                <FeedFactoryListView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderFeedFactoryFormRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.feedFactory.read} {...props}>
            <MainLayout pageName={"feedFactories"} {...props}>
                <FeedFactoryDetailsForm {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderFeedMovementsRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.batches.feedMovements.read} {...props}>
            <MainLayout pageName={"feedMovements"} {...props}>
                <FeedMovementsListView{...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderFeedMovementsFormRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.batches.feedMovements.read} {...props}>
            <MainLayout pageName={"feedMovements"} {...props}>
                <FeedTransactionDetailsFormContainer{...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderFeedMovementsMultiFormRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.batches.feedMovements.read} {...props}>
            <MainLayout pageName={"feedMovements"} {...props}>
                <MultiFeedTransactionDetailsForm{...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderAnimalsMovementsRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.batches.transactions.read} {...props}>
            <MainLayout pageName={"animalMovements"} {...props}>
                <AnimalMovementsListView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderAutomaticFeedMovementsRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.batches.transactions.read} {...props}>
            <MainLayout pageName={"automaticFeedMovements"} {...props}>
                <AutomaticFeedMovementsListView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderAnimalsMovementsFormRoute(props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.batches.transactions.read} {...props}>
            <MainLayout pageName={"animalMovements"} {...props}>
                <AnimalMovementsDetailsFormContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderBatchDetailsRoute (props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.batches.transactions.read} {...props}>
            <MainLayout pageName={"batchDetails"} {...props}>
                <BaseBatchDetails {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderBatchFormRoute (props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute permissionName={permissions.batches.transactions.read} {...props}>
            <MainLayout pageName={"batchDetails"} {...props}>
                <BatchDetailsFormContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderTaskTemplateRoute (props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute {...props}>
            <MainLayout pageName={"taskTemplate"} {...props}>
                <TaskTemplateListView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderTaskTemplateFormRoute (props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute {...props}>
            <MainLayout pageName={"taskTemplate"} {...props}>
                <TaskTemplateFormContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderTaskRoute (props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute {...props}>
            <MainLayout pageName={"taskList"} {...props}>
                <TaskListView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderTaskFormRoute (props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute {...props}>
            <MainLayout pageName={"task"} {...props}>
                <TaskFormContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderTaskBoardRoute (props) {
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute {...props}>
            <MainLayout pageName={"taskBoard"} {...props}>
                <TaskBoardView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderDefaultRoute() {
    return <BlankLayout><NotFoundView/></BlankLayout>;
}

function renderReportSubscriptionRoute(props){
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute {...props}>
            <MainLayout pageName={"reportSubscriptions"} {...props}>
                <SubscriptionListView {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderReportSubscriptionFormRoute(props){
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute {...props}>
            <MainLayout pageName={"reportSubscriptions"} {...props}>
                <ReportFormContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderAnalyticsOverviewRoute(props){
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute {...props}>
            <MainLayout pageName={"analytics"} {...props}>
                <AnalyticsOverviewContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

function renderAnalyticsBatchRoute(props){
    return <Suspense fallback={<MainLoading/>}>
        <AuthenticatedRoute {...props}>
            <MainLayout pageName={"batchAnalytics"} {...props}>
                <AnalyticsBatchOverviewContainer {...props}/>
            </MainLayout>
        </AuthenticatedRoute>
    </Suspense>
}

export default Routes;