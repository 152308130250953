import React from 'react';
import DefaultTheme from "./themes/DefaultTheme";
import StagingTheme from "./themes/StagingTheme";
import AgrupaltoTheme from "./themes/AgrupaltoTheme";
import ValporTheme from "./themes/ValporTheme";
import TelefarmTheme from "./themes/TelefarmTheme";
import ThingsControlTheme from "./themes/ThingsControlTheme";

export const getTheme  = () => {
    const url = window.location.host;
    const tenant = url.split(".")[0];

    if(tenant.toLocaleLowerCase().includes(Tenants.TELEFARM.subDomain)) {
        return TelefarmTheme;
    }

    if(tenant.toLocaleLowerCase().includes(Tenants.AGRUPALTO.subDomain)) {
        return AgrupaltoTheme;
    }

    if(tenant.toLocaleLowerCase().includes(Tenants.VALPOR.subDomain)) {
        return ValporTheme;
    }

    if(tenant.toLocaleLowerCase().includes(Tenants.STG.subDomain)) {
        return StagingTheme;
    }

    if(tenant.toLocaleLowerCase().includes(Tenants.THINGSCONTROL.subDomain)) {
        return ThingsControlTheme;
    }

    return DefaultTheme;
};

export const getTenantId  = () => {
    const url = window.location.host;
    const tenant = url.split(".")[0];

    if(tenant.toLocaleLowerCase().includes(Tenants.TELEFARM.subDomain)) {
        return Tenants.TELEFARM.id;
    }
    if(tenant.toLocaleLowerCase().includes(Tenants.AGRUPALTO.subDomain)) {
        return Tenants.AGRUPALTO.id;
    }

    if(tenant.toLocaleLowerCase().includes(Tenants.VALPOR.subDomain)) {
        return Tenants.VALPOR.id;
    }

    if(tenant.toLocaleLowerCase().includes(Tenants.STG.subDomain)) {
        return Tenants.STG.id;
    }

    if(tenant.toLocaleLowerCase().includes(Tenants.THINGSCONTROL.subDomain)) {
        return Tenants.THINGSCONTROL.id;
    }

    return Tenants.FARMCONTROL.id;
};

const Tenants = {
    TELEFARM: {
        id: "farmcontrolTenant",
        subDomain: "telefarm"
    },
    AGRUPALTO: {
        id: "farmcontrolTenant",
        subDomain: "agrupalto"
    },
    VALPOR: {
        id: "farmcontrolTenant",
        subDomain: "valpor"
    },
    STG: {
        id: "farmcontrolTenant",
        subDomain: "stg"
    },
    THINGSCONTROL: {
        id: "farmcontrolTenant",
        subDomain: "platform"
    },
    FARMCONTROL: {
        id: "farmcontrolTenant",
        subDomain: ""
    }
};

export const ThemeContext = React.createContext(DefaultTheme); //default Theme